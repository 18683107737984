.changeBox {
  margin: 24px 0 40px 0;
  padding: 40px;
  overflow: auto;
  background-color: #F2F2F2;
  border: 1px solid #B2B2B2;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
}

.changeBox .changeSubmit {
  margin: 24px 30px 0 0;
  font-size: 16px;
  background-color: #454545;
  /* layer fill content */
  border: 1px solid #000;
  /* stroke */
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.changeBox .changeBoxTitle {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  margin-bottom: 12px;
  text-shadow: 0 1px #FFF;
}

.changeBox label {
  display: block;
  margin-bottom: 6px;
}

.changeArea {
  position: relative;
  margin: 0 30px 24px 30px;
  min-height: 30px;
  font-size: 16px;
  color: #656565;
}

.changeBox input:not([type=submit]), .changeBox select {
  display: block;
  height: 28px;
  line-height: 28px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 1, 1, .2);
  padding-left: 10px;
  /* stroke */
  background-color: #e5e5e5;
  /* color overlay */
  -moz-box-shadow: inset 0 1px 1px rgba(0, 1, 1, .1);
  /* inner shadow */
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 1, 1, .1);
  /* inner shadow */
  box-shadow: inset 0 1px 1px rgba(0, 1, 1, .1);
}

.changeBox input[type='checkbox'] {
  width: 30px;
  height: 30px;
  padding-left: 0;
  border: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.requiredBlip, .errorBlip {
  position: absolute;
  left: -20px;
  top: 32px;
  width: 10px;
  height: 10px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 1px 0 rgba(0, 0, 0, .5);
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.errorBlip {
  background-color: #d92323;
}

.requiredBlip {
  background-color: #49A942;
}

.errorField {
  color: #D82323;
  font-size: 14px;
}

.changeSubmit {
  margin-top: 20px;
  padding: 14px 26px;
  color: #ccc;
  font-size: 14px;
  text-shadow: 0 -1px 0 rgba(0, 1, 1, 0.5);
  border: 1px solid #212122;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #454545;
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15);
  text-decoration: none;
}

.changeSubmit:hover {
  color: #FFF;
}

#errorMessageArea {
  position: relative;
  font-weight: 600;
  color: #d92323;
  margin-bottom: 16px;
  margin-left: 20px;
}

#errorMessageArea .errorBlip {
  left: -16px;
  top: 4px;
}

#loginExisting.changeBox {
  display: flex;
}

#loginExisting.changeBox .changeArea {
  flex: 1 1 50%;
}

#loginExisting.changeBox .changeActions {
  flex: 0 0 auto;
}

#loginForgotPassword {
  color: #666;
  font-size: 14px;
  text-decoration: none;
}

#loginForgotPassword:hover {
  color: #333;
}

.loginInfo {
  margin-bottom: 40px;
}

.loginInfo .loginInfoTitle {
  margin-top: 0;
  margin-bottom: 8px;
}

.loginInfo .loginInfoRow {
  display: flex;
}

.loginInfo .loginInfoRow .loginInfoRowText {
  flex: 1 1 auto;
  font-size: 16px;
}

.loginInfo .loginInfoRow .loginInfoRowText a {
  font-weight: 600;
  color: #333;
  text-decoration: none;
}

.loginInfo .loginInfoRow .loginInfoRowText a:hover {
  color: #000;
  text-decoration: underline;
}

.loginInfo .loginInfoRow .loginInfoRowButton {
  flex: 0 0 auto;
  margin-left: 8px;
}

.loginInfoRowButton .muiButton {
  padding: 12px 14px;
}

.checkboxRow, #requiredArea {
  display: flex;
  align-items: center;
  position: relative;
}

.checkboxRow input[type="checkbox"], .checkboxRow label {
  flex: 0 0 auto;
}

.checkboxRow input[type="checkbox"] {
  margin-right: 8px;
}

.checkboxRow label {
  margin-bottom: 0;
}

#requiredArea {
  margin-top: 32px;
  margin-bottom: 0;
}

.checkboxRow .requiredBlip, .checkboxRow .errorBlip, #requiredArea .requiredBlip {
  top: 30%;
}